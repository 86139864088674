import "./cartItem.css";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { useState } from "react";
import { useContext } from "react";
import CartContext from "../../CartContext";

const CartItem = ({ id, img, name, price }) => {
  const { removeFromCart, changeQuantity } = useContext(CartContext);
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (e) => {
    if (e.target.value < 1) {
      setQuantity(1);
    } else {
      setQuantity(e.target.value);
      changeQuantity(id, e.target.value);
    }
  };

  return (
    <div className="py-4 px-10 grid grid-cols-1 md:grid-cols-5 my-20 hover:bg-[#03474807] w-full">
      <div className="flex items-center justify-center">
        <img src={img} alt={name} className="w-36" />
      </div>
      <div className="flex items-center justify-center mt-2 md:mt-0">
        <Link
          className="text-[#034748] text-center hover:text-green-700"
          to={`/p/${id}`}
        >
          {name}
        </Link>
      </div>

      <div className="flex items-center justify-center mt-2 md:mt-0">
        <input
          className="p-4 w-16 h-12 "
          type="number"
          name="quantity"
          min="1"
          value={quantity}
          onChange={handleQuantityChange}
        />
      </div>
      <div className="flex items-center justify-center mt-2 md:mt-0">
        <span className="cart-price">{price * quantity}DA</span>
      </div>
      <button
        className="cursor-pointer text-red-600 flex items-center justify-center mt-2 md:mt-0"
        onClick={() => removeFromCart(id)}
      >
        <AiFillDelete className="hover:text-red-400" size={18} />
      </button>
    </div>
  );
};

export default CartItem;
